@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,900&display=swap');

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
  color: #fff;
  font-size: 1em;
  line-height: 1.4;
}

body {
  color: #fff;
}

::-moz-selection {
  background: #000;
  text-shadow: none;
}

::selection {
  background: #000;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

.modal {
  color: #000 !important;
}
.modal-content h6 {
  font-weight: bold;
}
.modal-body {
  font-size: 0.9rem;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

*{
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
}
.header{
    display: flex;
    flex-direction: column;  
    height: 604px;
    width: 100%;
    background-image: url(../img/hero-bg.png);
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

nav{
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    border-radius: 6px; 
    height: 50px;
    margin: 0.7rem 2.2rem 1rem 2.2rem;
}
nav img{
    width: 135px;
}
.nav-logo {
  display: flex;
  flex-direction: column;
}
.nav-logo img {
  margin-bottom: 0.1rem;
}
.nav-logo-main {
  width:100%; 
  height:auto; 
  max-width:120px;
}
.nav-logo-sub {
  align-self: flex-end;
  width:100%; 
  height:auto; 
  max-width:80px;
}
.nav-links{
    flex: 1;
    text-align: right;
}
.nav-links ul {
  margin-bottom: 0;
}
.nav-links ul li{
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
}
.nav-links ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 0.7rem;
}
.nav-links ul li::after{
    content: '';
    width: 0%;
    height: 2px;
    background: #f44336;
    display: block;
    margin: auto;
    transition: .5s;    
}
.nav-links ul li:hover::after{
    width: 100%;
}
nav .fa{
    display: none;
}

.hero-logo {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
.hero-logo img {
  margin-bottom: 0.2rem;
}
.hero-logo-main {
  width:100%; 
  height:auto; 
  max-width:600px;
  z-index: 3;
}
.hero-logo-sub {
  align-self: flex-end;
  width:100%; 
  height:auto; 
  max-width:208px;
  z-index: 3;
}
.hero-maintext {
  margin-bottom: 40px !important;
}
.hero-subtext {
  font-weight: 600;
  height: auto;
}

.text-box{
    display: flex;
    flex-direction: column;
    width: 90%;
    color: #fff;
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top: auto;
}
.text-box h1{
    font-size: 62px;
}
.text-box p{
    margin: 50px 0 40px;
    font-size: 1rem;
    color: #fff;
    max-width: 560px;
    align-self: center;
}
.hero-btn {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 34px;
    font-size: 13px;
    background: transparent;
    position: relative;
    cursor: pointer;
    transition: 1s;
}

.hero-btn:hover{
    border: 1px solid #f44336;
    background: #f44336;
}

.header-angles-container {
  justify-content: space-between;
  margin-top: auto;
  display: flex;
  flex-direction: row;
}
.hero-left-angles {
  align-self: flex-start;
  width:100%; 
  height:auto; 
  max-width:50%;
  z-index: 3;
}
.hero-right-angles {
  align-self: flex-end;
  width:100%; 
  height:auto; 
  max-width:50%;
  z-index: 3;
}

@media(max-width: 940px)
{
  .nav-logo-main { 
    max-width:100px;
  }
  .nav-logo-sub { 
    max-width:76px;
  } 
  .nav-links ul li
    {
        display: block;
    }
    .nav-links
    {
        position: fixed;
        background: #dc3545;
        height: 100vh;
        width: 200px;
        top: 0;
        right: -200px;
        text-align: left;
        z-index: 4;
        transition: 0.5s;
    }
    .nav-links ul{
        padding: 30px;
    }
    nav .fa{
        display: block;
        color: #fff;
        margin: 10px;
        font-size: 22px;
        cursor: pointer; 
    }
    .text-box h1{
        font-size: 20px;
    }
}

/*---------- Quote 1 -------*/
.quote-container {
  display: flex;
  flex-direction: column; 
  background-image: url(../img/blue-gradient-bg.png);
  background-repeat: repeat-x;
  height: 278px;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.quote-lines {
  display: flex;
  flex-direction: column; 
  background-image: url(../img/quote-lines.png);
  background-position: center;
  background-size: cover;
  height: 29px;
  width: 50px;
  align-self: center;
  margin: 0 0 20px;
}
.quote-container p {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  width: 800px;
  align-self: center
}

@media (max-width:900px){
  .quote-container p {
    width: 600px;
    font-size: 1rem;
  }
}

@media (max-width:700px){
  .quote-container p {
    font-size: 0.9rem;
    width: 300px;
  }
} 

/*---------- About -------*/
.about-container {
  display: flex;
  flex-direction: column; 
  background-image: url(../img/about-bg.png);
  background-position: center;
  background-size: cover;
  height: 800px;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.about-container div {
  align-self: flex-end;
  margin: 0 40px 40px 40px;
}
.about-container h4 {
  font-weight: 800;
}
.about-container p {
  font-size: 1rem;
  font-weight: 400;
  width: 400px;
  line-height: 2.2rem;
  padding: 0 !important;
}

@media (max-width:700px){
  .about-container {
    background-position: -124% 0;
  }
  .about-container div {
    margin: 195px 40px 40px 40px;
  }
  .about-container h4 {
    text-shadow: 1px 1px #000;
  }
  .about-container p {
    font-size: 0.9rem;
    width: 300px;
    line-height: 1.2rem;
    text-shadow: 1px 1px #000;
  }
} 

/*---------- Content --------*/
.row{
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
.content-container {
  display: flex;
  flex-direction: column;  
  height: 100%;
  width: 100%;
  background-image: url(../img/large-content-bg.png);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  margin: auto;
  text-align: center;
  padding-top: 80px;
}
.content-container h4 {
  font-weight: 800;
}
.content-linear {
  line-height: 2rem;
  width: 840px;
  align-self: center;
  margin-bottom: 20px;
}
.content-col{
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  align-self: center;
  width: 140px;
  margin: 20px;
}
.content-col img{
  width: 140px;
}

.layer{
  background: transparent;
  height: 100%;
  width: 103%;
  top: 40px;
  left: 10px;
  position: absolute;
  transition: .5s;
  border-radius: 6px;
}
.layer h3{
  width: 100%;
  font-weight: 600;
  color: #fff;
  font-size: 0.9rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  transition: .5s;
}

.mt-20 {
  margin-top: 16px;
}

.digital-container {
  margin: 60px 0 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.digital-subcontainer {
  display: flex;
  flex-direction: row;
  margin: 60px;
  width: 80%;
}
.digital-container div img {
  height: 380px;
  width: 604px;
}
.digital-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: center;
  margin-left: 20px;
}

@media (max-width:900px){
  .content-linear {
    width: 600px;
  }
  .digital-container div img {
    height: 320px;
    width: 544px;
  }
  .mt-50 {
    margin-top: 60px; 
  }
}

@media (max-width:700px){
  .content-linear {
    font-size: 0.9rem;
    width: 300px;
    margin-bottom: 50px;
  }
  .content-col {
    margin: 20px 20px 70px 0;
  }
  .row{
      flex-direction: row;
  }
  .digital-content {
    text-align: center;
    margin: 40px 0 0 20px;
}
  .digital-subcontainer {
    flex-direction: column;
    margin: 40px 60px 60px 50px;
  }
  .digital-container div img {
    height: 204px;
    width: 324px;
  }  
}

/*---------- Leadership --------*/
.leadership-container {
    display: flex;
    flex-direction: column;  
    height: 100%;
    width: 100%;
    background-image: url(../img/leadership-bg.png);
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    justify-content: center;
    text-align: center;
    margin: auto;
    text-align: center;
    padding: 50px 0 60px 0;
  }
.leadership-container h4 {
  font-weight: 800;
}
.leadership-linear {
  line-height: 2rem;
  width: 840px;
  align-self: center;
}
.leadership-col{
  border-radius: 10px;
  position: relative;
  align-self: center;
  margin: 20px;
}
.leadership-col h5 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.leadership-col img{
  width: 160px;
  margin: 20px;
}
.rc-4 {
  border-radius: 3px 3px;
}
.leadership-col img:hover{
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);
  transition: .5s;
}
.leadership-col p {
  width: 820px;
  text-align: left;
  font-size: 0.8rem;
}
.leadership-subtitle {
  margin: 0 0 30px 0;
  display: block;
}

@media (max-width:900px){ 
  .leadership-col p {
    width: 500px;
  }
}
@media (max-width:700px){ 
  .leadership-col p {
    width: 280px;
  }
}

/*---------- Press Release --------*/
.pr-container {
  display: flex;
  flex-direction: column; 
  background-image: url(../img/pr-bg.png);
  background-position: center;
  background-size: cover;
  height: 800px;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 80px;
}
.pr div {
  align-self: flex-end;
  margin: 0 40px 40px 40px;
}
.pr-container h4 {
  font-weight: 800;
}
.pr-container p {
  font-size: 1rem;
  font-weight: 400;
  width: 400px;
  line-height: 2.2rem;
  padding: 0 !important;
}

@media (max-width:700px){
  .pr-container {
    background-position: 72% 0;
    flex-direction: row;
  }
  .pr-container div {
    margin: 342px 0 0 0;
  }
  .pr-container h4 {
    text-shadow: 1px 1px #000;
  }
  .pr-container p {
    font-size: 0.9rem;
    width: 300px;
    line-height: 1.2rem;
    text-shadow: 1px 1px #000;
  }
} 

/*---------- Contact --------*/
.contact-container {
  display: flex;
  flex-direction: column; 
  background-image: url(../img/contact-bg.png);
  background-position: 100% 0;
  background-size: cover;
  height: 608px;
  width: 100%;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.contact div {
  align-self: flex-end;
  margin: 0 40px 40px 40px;
}
.contact-container h4 {
  font-weight: 800;
  margin-bottom: 20px;
}

.contact-us{
  width: 80%;
  margin: auto;
}
.contact-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-col div{
  display: flex;
  align-items: center;
}

.contact-form-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-col input {
  width: 100%;
  padding: 15px;
  margin: 0 0 20px 0;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}

.contact-col textarea{
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}

.contact-col form {
  display: flex;
  flex-direction: column;
}

.g-recaptcha {
  margin: 10px 0 20px 0;
  align-self: flex-end;
}

.contact-col button {
  width: 120px;
  align-self: flex-end;
}

.mr-20 {
  margin-right: 20px !important;
}

.success-sent-email {
  opacity: 0;
  margin: -40px 0 0 0;
  width: 230px;
}
.fa-spin {
  top: 50%;
  position: absolute;
  color: #333;
  margin-left: -6000px;
}

@media (max-width:700px){
  .contact-container {
    background-position: 86% 0;
  }
  .contact-container .contact-col {
    margin: 0 30px 20px 30px;
  }
  .contact-container h4 {
    text-shadow: 1px 1px #000;
  }
  .contact-col input {
    margin: 0;
  }
  .contact-form-inputs {
    width: 100%;
    margin-bottom: 20px;
  }
  .success-sent-email {
    width: 140px;
  }
} 

/*--------------Footer---------------*/

.footer{
  background-color: #000;
  width: 100%;
  text-align: center;
  padding: 30px 0;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer img{
  width: 135px;
}
.footer-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.footer-logo img {
  margin-bottom: 0.1rem;
}
.footer-logo-main {
  width:100%; 
  height:auto; 
  max-width:120px;
}
.footer-logo-sub {
  align-self: center;
  width:100%; 
  height:auto; 
  max-width:80px;
  margin-left: 28px;
}
.footer p {
  font-size: 0.7rem;
}


/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

